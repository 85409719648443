import SeoHelment from "../common/seoHelment";
import { seoParams } from "../../models/seoParams";

function GetAboutUs() {
  const language = localStorage.getItem("language");
  let seoObject: seoParams = {
    title: language == "en" ? "About Us" : "關於我們",
    description: "Who are we and what are our values",
    keywords:
      "[values, principles, epic, lesgo epic, letsgo epic, who are we, what are we]",
    meta: {
      name: `description`,
      content: `LesGo Epic aspires to connect more people through social events where
      participants meet different people with diverse backgrounds in fun and
      engaging activities. These activities push participants to socialize
      with one another as they usually require them to team up and overcome
      challenges in a safe environment. Our events inspire people to connect
      with one another over memorable, value-driven experiences.`,
    },
  };
  return (
    <div className="card card-form">
      <SeoHelment {...seoObject} />
      <div className="about-us">
        <h1>{language == "en" ? "Mission" : "使命"}</h1>
        <p className="formNotes">
          {language == "en" ? (
            <p className="formNotes">
              Instead of wasting another weekend on low-quality hangouts in
              bars, restaurants, etc., join us at our events where you can talk
              to more than 60 like-minded individuals while not worrying about
              any logistics of the outdoor experience. We take care of
              everything from food, drinks, equipment to medical kits, and bug
              repellents.
              <br />
              <br />
              Our events include all-in Camping Trips, BBQ Parties, Gelato &
              Beach Parties, Kayaking, and Surfing. Here at LesGo Epic, we
              prioritize and guarantee that you will have the best outdoor
              experience and have the best story to tell your friends about
              after the event. All our events have a 100% satisfaction guarantee
              or we will fully refund you and your friends.
            </p>
          ) : (
            <p className="formNotes">
              不要再浪费周末时间在酒吧，餐馆等低质量的场所。加入我们的活动，您可以与60多位志同道合的人交谈，而无需担心户外活动的任何后勤问题。我们会照顾一切，从食物，饮料，设备到医疗包和防虫剂。
              <br />
              <br />
              我们的活动包括全包的露营之旅，烧烤派对，雪糕和海滩派对，皮划艇，冲浪。在LesGo
              Epic，我们优先保证您将拥有最佳的户外体验，并在活动结束后向朋友讲述最好的故事。我们所有的活动都有100%的满意度保证，否则我们将全额退款给您和您的朋友。
            </p>
          )}
        </p>
        <h1>
          {language === "en" ? "Who joins our events?" : "誰參加我們的活動？"}
        </h1>
        <p className="formNotes">
          {language === "en" ? (
            <p className="formNotes">
              Our events are popular among Hong Kong's busy professionals. We
              focus on providing the most amount of high-quality time to Hong
              Kong's busy working professionals where you get the best outdoor
              experience without having to prepare/plan anything in advance.
              <br />
              <br />
            </p>
          ) : (
            <p className="formNotes">
              我們的活動在香港的忙碌專業人士中非常受歡迎。我們專注於為香港的忙碌工作人士提供最多的高質量時間，您可以在不需要提前準備/計劃任何事情的情況下獲得最好的戶外體驗。我們所有的活動都是全包的體驗，一切都照顧好，參與者不需要“帶/準備”任何東西。
              <br />
              <br />
            </p>
          )}
        </p>
        <h1>{language === "en" ? "Why join us?" : "為什麼要加入我們？"}</h1>
        <p className="formNotes">
          {language == "en" ? (
            <p className="formNotes">
              With more than 800 participants and 25 events organized, we offer
              the best outdoor event experience in Hong Kong. LesGo Epic is
              committed to giving you an unforgettable outdoor experience where
              you can take your feet off the work pedal while also enjoying your
              rest days with your loved ones!
              <br />
              <br />
            </p>
          ) : (
            <p className="formNotes">
              我們已經有超過800名參與者，並舉辦了25場活動，我們提供香港最好的戶外活動體驗。LesGo
              Epic承諾為您提供一個難忘的戶外體驗，在這裡您可以從工作中抽出腳，同時也可以和您的愛人享受休息的日子！
              <br />
              <br />
            </p>
          )}
        </p>
        <h1>{language == "en" ? "Last words?" : "最後的話？"}</h1>
        <p className="formNotes">
          {language == "en" ? (
            <p className="formNotes">
              Try joining an event of ours,<br />
              Worst case scenorio, you have a story that can make the people you
              love laugh<br />
              Best case scenorio, you have a story that can make the people you
              love laugh
              <br />
              <br />
            </p>
          ) : (
            <p className="formNotes">
              試著參加我們的一個活動，
              <br />
              最壞的情況，你有一個能讓你愛的人笑的故事。
              <br />
              最好的情況，你有一個能讓你愛的人笑的故事。
              <br />
              <br />
            </p>
          )}
        </p>
      </div>
    </div>
  );
}

export default GetAboutUs;
