import GetYouTubeVideo from "./youtubeVideo";
import GoogleReviews from "./googleReviews";
import BriefCard from "./briefCard";
import SeoHelment from "../common/seoHelment";
import { seoParams } from "../../models/seoParams";
import { useEffect } from "react";

function Dashboard() {
  const language = localStorage.getItem("language");
  let seoObject: seoParams = {
    title: language == "en" ? "Dashboard" : "儀表板",
    description: "HK's only outdoor cinema, all-in camping experience, beach parties, ice cream parties",
    keywords: "[events, lesgo, epic, lesgo epic, letsgo epic, dashboard, ice cream, beach, camping, outdoor]",
    meta: {
      name: `description`,
      content: "Dashboard",
    },
  };
  useEffect(() => {
    const counters = document.querySelectorAll(".counter");
    counters.forEach((counter: any) => {
      counter.innerText = "0";
      const updateCounter = () => {
        const target = +counter.getAttribute("data-target");
        const count = +counter.innerText;
        const increment = target / 200;
        let text = target == 800 ? "Participants" : "Events";
        if (count < target) {
          counter.innerText = `${Math.ceil(count + increment)}`;
          setTimeout(updateCounter, 1);
        } else counter.innerText = target + " + " + text;
      };
      updateCounter();
    });
  }, []);
  return (
    <div>
      <SeoHelment {...seoObject} />
      <BriefCard />
      <div className="container">
        <div className="stats row">
          <div className="counter-container col-8">
            <p className="counter" data-target="800"></p>
          </div>
          <div className="counter-container col-4">
            <p className="counter" data-target="25"></p>
          </div>
          <p className="brief-text row">
            {language == "en"
              ? `LesGo Epic is a refreshing escape for those caught in the fast-paced
            rhythm of life, particularly for those who know the hustle of Hong
            Kong's professional world all too well. We're here to offer a pivot
            from the predictable, trading the city's high-rises for the high
            seas, and office lights for starlit nights. Our outdoor experiences,
            featuring camping, outdoor cinema, and beach parties, are more than
            just a break from the norm. They're a chance for like-minded
            individuals to connect, explore, and discover that there's always
            room for adventure in the schedule.`
              : `LesGo Epic為那些身處快節奏生活中，特別是深知香港專業世界奔波辛勞的人們，提供一種清新的逃脫。我們在此提供一種轉變，將城市的高樓大廈換成廣闊的海洋，將辦公室的燈火換成星光燦爛的夜晚。我們的戶外體驗，包括露營、戶外電影和沙灘派對，不僅僅是從常規中休息。它們為志同道合的人提供了一個機會去聯繫、探索並發現，日程表中總是有空間去冒險。`}
          </p>
        </div>
      </div>
      <GoogleReviews />

      {/* <InstagramReels /> */}
      <GetYouTubeVideo />
    </div>
  );
}

export default Dashboard;
