import { useEffect } from "react";

function GoogleReviews() {
  return (
    <div className="container">
      <iframe
        height="800px"
        id="testimonialto-google-reviews2-tag-all-light-animated"
        src="https://embed-v2.testimonial.to/w/google-reviews2?animated=on&theme=light&shadowColor=f7f7f7&speed=1&tag=all"
        className="googleReviews"
        frameBorder="0"
        scrolling="no"
        width="100%"
      ></iframe>
    </div>
  );
}

export default GoogleReviews;
