import GoogleReviews from "../dashboard/googleReviews";

function Submitted() {
  const language = localStorage.getItem("language");
  return (
    <div>
      <center>
        <h4>
          {language == "en"
            ? "Thank you for sharing your thoughts with us!"
            : "感謝您與我們分享您的想法！"}
        </h4>
      </center>
      <GoogleReviews />
    </div>
  );
}

export default Submitted;
